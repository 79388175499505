var render = function () {
  var _vm$schedulePayload;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen"
  }, [_c('div', {
    staticClass: "mb-6 flex items-center gap-3"
  }, [_c('ArrowForward', {
    staticClass: "cursor-pointer",
    attrs: {
      "direction": "left"
    },
    on: {
      "handleClick": function handleClick() {
        _vm.$router.push('/voucher');
      }
    }
  }), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.$route.meta.mode) + " Voucher")])], 1), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white"
  }, [_c('div', {
    staticClass: "flex items-stretch shadow-border-b"
  }, [_c('div', {
    staticClass: "p-6 w-1/2 flex flex-col gap-4"
  }, [_c('Dropdown', {
    attrs: {
      "label": "Select Class",
      "options": _vm.list_kelas,
      "default": _vm.selectedCoreClass,
      "placeholder": "Select Class",
      "optionLabel": "nama",
      "maxHeight": "300px",
      "isLoadData": _vm.isFetchingCoreClass,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getCoreClassList
    },
    model: {
      value: _vm.selectedCoreClass,
      callback: function callback($$v) {
        _vm.selectedCoreClass = $$v;
      },
      expression: "selectedCoreClass"
    }
  }), _c('Dropdown', {
    attrs: {
      "label": "Select Schedule (Optional)",
      "options": _vm.lmsClassList,
      "default": _vm.selectedLmsClass,
      "placeholder": "Select Schedule",
      "optionLabel": "nama",
      "disabled": !_vm.selectedCoreClass,
      "maxHeight": "300px",
      "isLoadData": _vm.isFetchingLmsClass,
      "enableSearch": "",
      "notes": _vm.scheduleNotes
    },
    on: {
      "search": _vm.getLmsClassList
    },
    model: {
      value: _vm.selectedLmsClass,
      callback: function callback($$v) {
        _vm.selectedLmsClass = $$v;
      },
      expression: "selectedLmsClass"
    }
  }), _c('TextField', {
    attrs: {
      "borderEnabled": "",
      "label": "Voucher Code",
      "placeholder": "Enter Voucher Code"
    },
    model: {
      value: _vm.selectedCode,
      callback: function callback($$v) {
        _vm.selectedCode = $$v;
      },
      expression: "selectedCode"
    }
  })], 1), _c('div', {
    staticClass: "p-6 w-1/2 flex flex-col gap-4"
  }, [_c('Dropdown', {
    attrs: {
      "options": _vm.digitalPlatforms,
      "label": "Ecommerce",
      "optionLabel": "name",
      "default": _vm.selectedDP,
      "placeholder": "Select Ecommerce"
    },
    model: {
      value: _vm.selectedDP,
      callback: function callback($$v) {
        _vm.selectedDP = $$v;
      },
      expression: "selectedDP"
    }
  }), _c('Dropdown', {
    attrs: {
      "options": _vm.dummyOption,
      "label": "Dummy",
      "capitalize": "",
      "default": _vm.options['dummy'],
      "placeholder": "Voucher Status"
    },
    model: {
      value: _vm.options['dummy'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'dummy', $$v);
      },
      expression: "options['dummy']"
    }
  }), _c('TextField', {
    attrs: {
      "borderEnabled": "",
      "label": "Discount Amount",
      "placeholder": "Discount Amount"
    },
    model: {
      value: _vm.options['discount_amount'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'discount_amount', $$v);
      },
      expression: "options['discount_amount']"
    }
  })], 1)]), _c('div', {
    staticClass: "flex justify-end items-center p-6"
  }, [_c('Button', {
    attrs: {
      "buttonText": _vm.modeForm === 'Edit' ? 'Update' : 'Submit',
      "disabled": !_vm.isSubmitBtnVisible
    },
    on: {
      "action": _vm.submitData
    }
  })], 1)])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleAlertModal,
      "width": "600px",
      "id": "upload-voucher"
    },
    on: {
      "close": function close($event) {
        return _vm.toogleAlertModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', [_c('h1', {
    staticClass: "font-bold text-2xl"
  }, [_vm._v("Schedule Code Unavailable")]), _c('h3', {
    staticClass: "py-3"
  }, [_vm._v(" Schedule code of "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v(_vm._s((_vm$schedulePayload = _vm.schedulePayload) === null || _vm$schedulePayload === void 0 ? void 0 : _vm$schedulePayload.nama))]), _vm._v(" is not available, please enter new schedule code to continue the process ")]), _c('TextField', {
    staticClass: "mb-5",
    attrs: {
      "borderEnabled": "",
      "placeholder": "Enter Schedule Code"
    },
    model: {
      value: _vm.schedulePayload['scheduleCodePMO'],
      callback: function callback($$v) {
        _vm.$set(_vm.schedulePayload, 'scheduleCodePMO', $$v);
      },
      expression: "schedulePayload['scheduleCodePMO']"
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Continue",
      "size": "big"
    },
    on: {
      "action": _vm.updateLmsClass
    }
  })], 1)])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }