<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="mb-6 flex items-center gap-3">
        <ArrowForward
          direction="left"
          class="cursor-pointer"
          @handleClick="
            () => {
              $router.push('/voucher')
            }
          "
        />
        <h1 class="text-3xl font-bold">{{ $route.meta.mode }} Voucher</h1>
      </div>
      <div class="shadow-small rounded-lg bg-white">
        <div class="flex items-stretch shadow-border-b">
          <div class="p-6 w-1/2 flex flex-col gap-4">
            <Dropdown
              label="Select Class"
              :options="list_kelas"
              v-model="selectedCoreClass"
              :default="selectedCoreClass"
              placeholder="Select Class"
              optionLabel="nama"
              @search="getCoreClassList"
              maxHeight="300px"
              :isLoadData="isFetchingCoreClass"
              enableSearch
            />
            <Dropdown
              label="Select Schedule (Optional)"
              :options="lmsClassList"
              v-model="selectedLmsClass"
              :default="selectedLmsClass"
              placeholder="Select Schedule"
              optionLabel="nama"
              :disabled="!selectedCoreClass"
              @search="getLmsClassList"
              maxHeight="300px"
              :isLoadData="isFetchingLmsClass"
              enableSearch
              :notes="scheduleNotes"
            />
            <TextField borderEnabled v-model="selectedCode" label="Voucher Code" placeholder="Enter Voucher Code" />
          </div>

          <div class="p-6 w-1/2 flex flex-col gap-4">
            <Dropdown :options="digitalPlatforms" label="Ecommerce" optionLabel="name" v-model="selectedDP" :default="selectedDP" placeholder="Select Ecommerce" />
            <Dropdown :options="dummyOption" label="Dummy" capitalize v-model="options['dummy']" :default="options['dummy']" placeholder="Voucher Status" />
            <TextField borderEnabled v-model="options['discount_amount']" label="Discount Amount" placeholder="Discount Amount" />
          </div>
        </div>
        <div class="flex justify-end items-center p-6">
          <Button :buttonText="modeForm === 'Edit' ? 'Update' : 'Submit'" :disabled="!isSubmitBtnVisible" @action="submitData" />
        </div>
      </div>
    </div>
    <Modal :modalVisible="visibleAlertModal" @close="toogleAlertModal()" width="600px" id="upload-voucher">
      <template slot="modal-content">
        <div>
          <h1 class="font-bold text-2xl">Schedule Code Unavailable</h1>
          <h3 class="py-3">
            Schedule code of <span class="font-semibold">{{ schedulePayload?.nama }}</span> is not available, please enter new schedule code to continue the process
          </h3>
          <TextField class="mb-5" borderEnabled v-model="schedulePayload['scheduleCodePMO']" placeholder="Enter Schedule Code" />
          <Button buttonText="Continue" size="big" @action="updateLmsClass" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'VoucherForm',
  mounted() {
    this.modeForm = this.$route.meta.mode
    if (this.modeForm == 'Edit') {
      this.idForm = this.$route.params.id
    }
    this.initData()
  },
  data() {
    return {
      lmsClassList: [],
      isFetchingLmsClass: false,
      isFetchingCoreClass: false,
      isSearchingLmsClass: false,
      selectedCoreClass: null,
      selectedLmsClass: null,
      selectedCode: '',
      selectedDP: null,
      isFetching: false,
      isSearching: false,
      classKeyword: '',
      debounce: null,
      dummyOption: ['true', 'false'],
      options: { code: '', dummy: false, ecommerce: '', core_class_id: '', discount_amount: 0 },
      voucherPayload: {},
      idForm: 0,
      modeForm: null,
      stillLoading: true,
      visibleAlertModal: false,
      schedulePayload: { scheduleCodePMO: '' }
    }
  },
  computed: {
    ...mapGetters('common', ['digitalPlatforms']),
    ...mapGetters('kelas', ['list_kelas']),
    customerId() {
      return localStorage.getItem('client')
    },
    data() {
      return this.$store.getters['voucher/list_voucher'] ? this.$store.getters['voucher/list_voucher'] : []
    },
    isEditMode() {
      return this.modeForm === 'Edit'
    },
    scheduleNotes() {
      return this.selectedCoreClass ? 'Expired schedule will not appear in the list' : 'Choose a class first'
    },
    isSubmitBtnVisible() {
      const copyData = JSON.parse(JSON.stringify(this.options))
      delete copyData?.code
      delete copyData?.codes
      return Object.values(copyData).filter((opt) => opt === '').length === 0
    }
  },
  components: {
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Trash: () => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  },
  watch: {
    $route(to, from) {
      this.initData()
    },
    selectedCoreClass(val) {
      delete this.options.lms_class_id
      this.options.core_class_id = val?.id
      this.selectedLmsClass = null
    },
    selectedLmsClass(val) {
      this.options.lms_class_id = val?.id
    },
    selectedDP(val) {
      this.options.ecommerce = val.code
    },
    selectedCode(val) {
      this.options.code = val
    }
  },
  methods: {
    ...mapActions('common', ['DIGITAL_PLATFORMS', 'showLoading', 'hideLoading']),
    ...mapActions('jadwalkelas', ['GET_JADWALKELAS_NEW', 'UPDATE_JADWALKELAS_NEW']),
    async toogleAlertModal() {
      if (this.visibleAlertModal === false) {
        this.schedulePayload = await this.getClassDetails()
      }
      this.visibleAlertModal = !this.visibleAlertModal
    },
    updateLmsClass() {
      this.showLoading()
      this.UPDATE_JADWALKELAS_NEW({
        customerId: this.customerId,
        lmsClassId: this.selectedLmsClass.id,
        payload: this.schedulePayload
      }).then(async (res) => {
        if (res.code === 422) {
          showVueToast('Failed to update schedule code!', 'error', 3000)
          this.hideLoading()
          return
        }
        if (res.status === 200) {
          this.submitData()
        } else {
          showVueToast(`${res?.errors?.error ? res?.errors?.error.charAt(0).toUpperCase() + res?.errors?.error.slice(1) : 'System error!'}!`, 'error', 3000)
          setTimeout(() => {
            this.hideLoading()
            this.temporaryDisabled = false
          }, 3500)
        }
      })
    },
    getCoreClassList(name = '') {
      this.isFetchingCoreClass = true
      const paramsTemp = queryString.stringify({
        ...{
          name,
          limit: 50
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          params: paramsTemp,
          customerId: this.customerId
        })
        .then(() => {
          this.isFetchingCoreClass = false
        })
    },
    getCoreClassByName(name = '') {
      return new Promise((resolve, reject) => {
        const paramsTemp = queryString.stringify({
          ...{
            name,
            limit: 50
          }
        })
        this.$store
          .dispatch('kelas/GET_LIST_KELAS', {
            params: paramsTemp,
            customerId: this.customerId
          })
          .then((res) => {
            resolve(res?.data?.data[0])
          })
      })
    },
    getLmsClassList(name = '') {
      this.isFetchingLmsClass = true
      const paramsTemp = queryString.stringify({
        ...{
          name,
          page: 0,
          limit: 50,
          core_class_id: this.selectedCoreClass?.id || '',
          is_schedule_available: true
        }
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          params: paramsTemp,
          customerId: this.customerId
        })
        .then((res) => {
          this.lmsClassList = res.data.data.map((lms) => {
            const nama = `${lms.nama} ( ${moment(lms.start_date).format('DD MMMM YYYY')} - ${moment(lms.end_date).format('DD MMMM YYYY')} )`
            return {
              ...lms,
              nama
            }
          })
          if (this.modeForm == 'Edit') {
            this.selectedLmsClass = this.lmsClassList.filter((val) => val.id === this.voucherPayload?.lms_class_id)[0]
            this.stillLoading = false
          }
          this.isFetchingLmsClass = false
        })
    },
    getClassDetails() {
      return new Promise((resolve, reject) => {
        this.GET_JADWALKELAS_NEW({
          lmsClassId: this.selectedLmsClass.id,
          customerId: this.customerId
        })
          .then((res) => {
            const result = JSON.parse(JSON.stringify(res?.data?.data))
            resolve(result)
          })
          .catch(() => {
            reject({ scheduleCodePMO: '' })
            showVueToast('LMS class schedule is not found!', 'error', 2000)
          })
      })
    },
    async initData() {
      await this.getDP()
      if (this.modeForm == 'Edit') {
        this.$store
          .dispatch('voucher/GET_VOUCHER', {
            masterType: this.$route.meta.name,
            customerId: this.customerId,
            masterId: this.idForm
          })
          .then(async (resp) => {
            if (resp.data.code == 200) {
              this.voucherPayload = this.$store.getters['voucher/voucher']
              this.options['dummy'] = this.voucherPayload.dummy.toString()
              this.options['discount_amount'] = this.voucherPayload.discount_amount.toString()
              this.getCoreClassList('', this.voucherPayload.core_class_id)
              this.selectedDP = this.digitalPlatforms.filter((item) => item.code === this.voucherPayload.ecommerce)[0]
              this.selectedCoreClass = await this.getCoreClassByName(this.voucherPayload.core_class_name)
              this.getLmsClassList()
              this.selectedCode = this.voucherPayload.code
            } else {
              // this.$swal(resp.data.voucher)
            }
            this.counterProgress = 100
          })
      } else {
        this.options['dummy'] = 'false'
        this.stillLoading = false
      }
    },
    validateDiscountAmount() {
      if(this.options['discount_amount'] < 0){
        return false
      }
      return true
    },
    submitData() {
      if (!this.validateDiscountAmount()){
        showVueToast('Discount amount can not negative', 'error', 2000)
        return
      }
      console.log('halo 2')
      this.showLoading()
      if (this.modeForm === 'Edit') {
        this.$store
          .dispatch('voucher/UPDATE_VOUCHER', {
            masterId: this.idForm,
            customerId: this.customerId,
            payload: this.options
          })
          .then((resp) => {
            this.visibleAlertModal = false
            showVueToast('Voucher updated succesfully!', 'success', 2000)
            if (resp.data.status === '200 OK') {
              this.hideLoading()
              this.$router.push('/' + this.$route.meta.name)
            } else {
              showVueToast('Failed to update voucher data!', 'error', 2000)
              this.hideLoading()
            }
          })
          .catch((err) => {
            if (err.errors.error == "schedule_code_pmo can't empty/null") {
              this.hideLoading()
              this.toogleAlertModal()
              return
            }
            showVueToast('Failed to updates voucher!', 'error', 2000)
            this.hideLoading()
          })
      } else {
        let newCodes = this.options.code + ',' + this.options.discount_amount
        this.options.codes = [newCodes]
        this.$store
          .dispatch('voucher/CREATE_VOUCHER', {
            masterId: this.idForm,
            customerId: this.customerId,
            payload: this.options
          })
          .then((resp) => {
            this.visibleAlertModal = false

            if (resp.data.status === '200 OK') {
              this.hideLoading()
              showVueToast('Voucher created succesfully!', 'success', 2000)
              this.$router.push('/' + this.$route.meta.name)
            } else {
              showVueToast('Failed to create voucher!', 'error', 2000)
              this.hideLoading()
            }
          })
          .catch((err) => {
            if (err.errors.error === "creating voucher failed. schedule_code_pmo can't empty/null") {
              this.hideLoading()
              this.toogleAlertModal()
              return
            }
            showVueToast('Failed to create voucher!', 'error', 2000)
            this.hideLoading()
          })
      }
    },
    getDP() {
      this.DIGITAL_PLATFORMS()
    },
    searchClass() {
      if (this.classKeyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(() => {
          if (this.classKeyword !== '') self.getListKelas(self.classKeyword)
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    selectClass(item) {
      this.options.core_class_id = item.id
      this.classKeyword = item.nama
      this.isSearching = false
    },
    getListKelas(searchTerm = '') {
      this.isFetching = true
      this.list_kelas = []
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      let paramsTemp = queryString.stringify({
        ...{
          limit: 50,
          name: searchTerm
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          masterType: this.$route.meta.name,
          customerId: this.customerId,
          params: paramsTemp
        })
        .then(() => {
          this.list_kelas = this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
          // this.hideLoading()
          this.isSearching = true
          this.isFetching = false
        })
        .catch(function () {})
    },
    getScheduleList(name = '') {
      this.isFetchingLmsClass = true
      const paramsTemp = queryString.stringify({
        ...{
          name,
          is_schedule_available: true,
          limit: 50
        }
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          params: paramsTemp,
          customerId: this.clientId
        })
        .then((res) => {
          this.lmsClassList = res.data.data.map((lms) => {
            const nama = `${lms.nama} ( ${moment(lms.start_date).format('DD MMMM YYYY')} - ${moment(lms.end_date).format('DD MMMM YYYY')} )`
            return {
              ...lms,
              nama
            }
          })
          this.isFetchingLmsClass = false
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
